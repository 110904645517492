import React from 'react';
import Link from 'next/link';
import { ShowOnDesktopOnly, ShowOnMobileOnly } from '@/components/show-on/ShowOn';
import Image from 'next/image';
import { getImageUrl } from '@/services/images.service';
import { contestPath } from '@/utils/utils';

interface IImageDimensions {
    mobile: {
        height: number;
        width: number;
    };
    desktop: {
        height: number;
        width: number;
    };
}

interface IContestImage {
    mobileImagePath: string;
    desktopImagePath: string;
    imageAlt: string;
    classes: string;
    imageDimensions?: IImageDimensions;
    hasToFill?: boolean;
    slug?: string;
}

export const ContestImage: React.FC<IContestImage> = ({
    hasToFill,
    mobileImagePath,
    desktopImagePath,
    imageAlt,
    classes,
    slug,
    imageDimensions,
}) => {
    const widthMobile = hasToFill ? undefined : imageDimensions?.mobile.width;
    const widthDesktop = hasToFill ? undefined : imageDimensions?.desktop.width;
    const contestImage = (
        <>
            <ShowOnMobileOnly>
                <Image
                    className={classes}
                    width={widthMobile}
                    src={getImageUrl(mobileImagePath)}
                    alt={imageAlt}
                    height={imageDimensions?.mobile.height}
                    fill={hasToFill}
                />
            </ShowOnMobileOnly>
            <ShowOnDesktopOnly>
                <Image
                    className={classes}
                    width={widthDesktop}
                    src={getImageUrl(desktopImagePath)}
                    alt={imageAlt}
                    height={imageDimensions?.desktop.height}
                    fill={hasToFill}
                />
            </ShowOnDesktopOnly>
        </>
    );

    return slug ? <Link href={contestPath(slug)}>{contestImage}</Link> : contestImage;
};
