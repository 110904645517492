'use client';

import React from 'react';
import styles from './CurrentContest.module.scss';
import { Button, ButtonColor, ButtonType } from '@/components/button/Button';
import classNames from 'classnames';
import { useTranslation } from '@app/i18n/useTranslationClient';
import { ContestImage } from '@/components/contests/contest-image/ContestImage';
import { CONTESTS_THUMBNAILS } from '@/services/imageSizes.service';
import { contestPath } from '@/utils/utils';

interface ICurrentContestStyles {
    currentContest?: string;
    currentContestReverted?: string;
    currentContestDescription?: string;
    currentContestDescriptionTitle?: string;
    currentContestDescriptionText?: string;
    currentContestImage?: string;
    currentContestValue?: string;
    currentContestLabel?: string;
}

interface ICurrentContest {
    slug: string;
    imageMobilePath: string;
    imageDesktopPath: string;
    imageAlt: string;
    header: string;
    content: string;
    date: string;
    isReverted?: boolean;
    classes?: ICurrentContestStyles;
    onContestsEnter?(): void;
}

export const CurrentContest: React.FC<ICurrentContest> = (props) => {
    const { t } = useTranslation();

    return (
        <div className={classNames(styles.currentContest, { [styles.currentContestReverted]: props.isReverted })}>
            <ContestImage
                mobileImagePath={props.imageMobilePath}
                desktopImagePath={props.imageDesktopPath}
                classes={styles.currentContestImage}
                imageAlt={props.imageAlt}
                imageDimensions={CONTESTS_THUMBNAILS}
                slug={props.slug}
            />
            <div
                data-testid={'contestDescription'}
                className={classNames(styles.currentContestDescription, props.classes?.currentContestDescription)}>
                <div>
                    <h2 className={styles.currentContestDescriptionTitle}>{props.header}</h2>
                    <div
                        className={styles.currentContestDescriptionText}
                        dangerouslySetInnerHTML={{ __html: props.content }}
                    />
                </div>
                <div className={styles.currentContestLabel}>
                    {t('translations.account.contests.applicationDeadline')}
                </div>
                <div className={styles.currentContestValue}>{props.date}</div>
                {!props.onContestsEnter && (
                    <Button
                        url={contestPath(props.slug)}
                        buttonType={ButtonType.LINK}
                        buttonColor={ButtonColor.BLACK}
                        classes={styles.currentContestJoinButton}>
                        {t('translations.account.contests.join')}
                    </Button>
                )}
                {props.onContestsEnter && (
                    <Button
                        onClick={props.onContestsEnter}
                        buttonType={ButtonType.BUTTON}
                        buttonColor={ButtonColor.BLACK}
                        classes={styles.currentContestJoinButton}>
                        {t('translations.account.contests.join')}
                    </Button>
                )}
            </div>
        </div>
    );
};
