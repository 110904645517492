'use client';

import React from 'react';
import styles from './ArchivedContestLabel.module.scss';
import { useTranslation } from '@app/i18n/useTranslationClient';

interface IArchivedContestLabel {
    isResolved: boolean;
}

export const ArchivedContestLabel: React.FC<IArchivedContestLabel> = ({ isResolved }) => {
    const { t } = useTranslation();

    const contestStatusInfo = isResolved
        ? t('translations.contest.status.resolved')
        : t('translations.contest.status.unresolved');

    return <div className={styles.label}>{contestStatusInfo}</div>;
};
