export const CONTEST_IMAGE = {
    mobile: {
        width: 311,
        height: 174,
    },
    desktop: {
        width: 744,
        height: 416,
    },
};

export const CONTESTS_THUMBNAILS = {
    mobile: {
        width: 279,
        height: 186,
    },
    desktop: {
        width: 589,
        height: 382,
    },
};

export const CONTESTS_LIST = {
    mobile: {
        width: 279,
        height: 186,
    },
    desktop: {
        width: 400,
        height: 285,
    },
};

export const ARCHIVED_CONTESTS = {
    mobile: {
        width: 279,
        height: 189,
    },
    desktop: {
        width: 436,
        height: 220,
    },
};

export const CONTESTS_WINNERS_IMAGE_SIZES = {
    mobile: {
        width: 311,
        height: 174,
    },
    desktop: {
        width: 744,
        height: 416,
    },
};
