export const imageUrl = process.env.NEXT_PUBLIC_IMG_URL;
export const paramsJoiner = ':';

type ResizingType = 'fit' | 'fill' | 'fill-down' | 'force' | 'auto';

interface ICustomImageProperties {
    width: number;
    height: number;
    type?: ResizingType;
    enlarge?: 0 | 1;
    extend?: 0 | 1;
}

export const getImageUrl = (imagePath: string, customImageProperties?: ICustomImageProperties) => {
    if (!customImageProperties) {
        return `${imageUrl}/${imagePath}`;
    }

    const { width, height, type = 'fit', enlarge = 0, extend = 0 } = customImageProperties;

    const combinedSize = `${width}x${height}`;
    const allProperties = [combinedSize, type, enlarge, extend];
    const combinedProperties = allProperties.join(paramsJoiner);

    return `${imageUrl}/${imagePath}/${combinedProperties}`;
};
