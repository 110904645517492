import(/* webpackMode: "eager" */ "/build/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/build/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/build/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-no-ssr.js");
import(/* webpackMode: "eager" */ "/build/src/components/arrow-button/ArrowButton.module.scss");
import(/* webpackMode: "eager" */ "/build/src/components/contests/archived-contest-label/ArchivedContestLabel.tsx");
import(/* webpackMode: "eager" */ "/build/src/components/contests/current-contest-section/CurrentContestSection.module.scss");
import(/* webpackMode: "eager" */ "/build/src/components/contests/contests-list/ArchivedContestLists.module.scss");
import(/* webpackMode: "eager" */ "/build/src/components/contests/current-contest/CurrentContest.tsx");
import(/* webpackMode: "eager" */ "/build/src/components/header/Header.tsx");
import(/* webpackMode: "eager" */ "/build/src/components/show-on/ShowOn.module.scss");
import(/* webpackMode: "eager" */ "/build/src/components/join/Join.module.scss");
import(/* webpackMode: "eager" */ "/build/src/components/current-contests/CurrentContests.module.scss");
import(/* webpackMode: "eager" */ "/build/src/components/welcome/Welcome.module.scss");
import(/* webpackMode: "eager" */ "/build/src/components/projects/Projects.module.scss");
import(/* webpackMode: "eager" */ "/build/src/components/how-it-works-step/HowItWorksStep.module.scss");
import(/* webpackMode: "eager" */ "/build/src/components/how-it-works/HowItWorks.module.scss");
import(/* webpackMode: "eager" */ "/build/src/components/promo/Promo.module.scss")